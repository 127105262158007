var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('ul',{staticClass:"tabs"},[_c('router-link',{attrs:{"to":{
        name: 'user-followers',
        params: { id: this.$route.params.id },
      }}},[_c('li',{staticClass:"tabs__followers",class:{ active: _vm.status.followers }},[_vm._v(" 跟隨者 ")])]),_c('router-link',{attrs:{"to":{
        name: 'user-followings',
        params: { id: this.$route.params.id },
      }}},[_c('li',{staticClass:"tabs__following",class:{ active: _vm.status.followings }},[_vm._v(" 正在跟隨 ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }